import { render, staticRenderFns } from "./AppTimeline.vue?vue&type=template&id=490f65df&scoped=true"
import script from "./AppTimeline.vue?vue&type=script&lang=js"
export * from "./AppTimeline.vue?vue&type=script&lang=js"
import style0 from "./AppTimeline.vue?vue&type=style&index=0&id=490f65df&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "490f65df",
  null
  
)

export default component.exports